import Img from 'gatsby-image'
import { chunk, sum } from 'lodash'
import React, { useState } from 'react'
import { Box, Flex } from 'rebass/styled-components'
import AnimationLink from '@elements/animationlink'
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'
import { useAppContext } from '@context/context'

const container = {
  hidden: {},
  show: {
    transition: {
      duration: 0.25,
      delay: 1,
      delayChildren: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
      staggerChildren: 0.5,
    },
  },
}

const item = {
  hidden: { opacity: 0 },
  show: i => ({
    opacity: 0.75,
    transition: {
      duration: 0.5,
      delay: 0.1 * i,
    },
  }),
  full: {
    opacity: 1,
  },
}
const MotionBG = styled(motion.div)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  cursor: pointer;
  bottom: 0;
  background-color: ${props => props.theme.primary};
  * {
    cursor: pointer;
  }
`
const LinkGallery = ({ projects, itemsPerRow: itemsPerRowByBreakpoints }) => {
  const images = projects.map(
    project => project.node.frontmatter.featureimage.childImageSharp.fluid
  )
  const aspectRatios = images.map(image => image.aspectRatio)

  // For each breakpoint, calculate the aspect ratio sum of each row's images
  const rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map(
    itemsPerRow =>
      // Split images into groups of the given size
      chunk(aspectRatios, itemsPerRow).map(rowAspectRatios =>
        // Sum aspect ratios of images in the given row
        sum(rowAspectRatios)
      )
  )
  const [, setIsHovered] = useState(false)
  const { setUseViewSwitch, useViewSwitch } = useAppContext()

  return (
    <>
      <AnimatePresence>
        <motion.div variants={container} initial="hidden" animate="show">
          {projects.map((project, i) => {
            const image =
              project.node.frontmatter.featureimage.childImageSharp.fluid
            const label = `${`${project.node.frontmatter.title} - a motion and design project made for ${project.node.frontmatter.client} by Andrew Hoag `}`
            const dataAnchorSpaced = project.node.frontmatter.title
            const dataAnchor = dataAnchorSpaced.replace(/ /g, '')
            return (
              <MotionBox
                layoutId={image.src}
                custom={i}
                key={image.src}
                variants={item}
                initial="hidden"
                animate="show"
                exit="hidden"
                whileHover="full"
                onHoverStart={() => {
                  setIsHovered(true)
                }}
                style={{
                  position: 'relative',
                  zIndex: 1000,
                }}
                width={rowAspectRatioSumsByBreakpoints.map(
                  // Return a value for each breakpoint
                  (rowAspectRatioSums, j) => {
                    // Find out which row the image is in and get its aspect ratio sum
                    const rowIndex = Math.floor(i / itemsPerRowByBreakpoints[j])
                    const rowAspectRatioSum = rowAspectRatioSums[rowIndex]

                    return `${(image.aspectRatio / rowAspectRatioSum) * 100}%`
                  }
                )}
                className={dataAnchor}
              >
                <MotionLink
                  to={project.node.fields.slug}
                  onClick={() => {
                    if (useViewSwitch) {
                      setUseViewSwitch(false)
                    }
                  }}
                  ariaLabel={label}
                >
                  <Img fluid={image} />
                  <Flex
                    as={MotionBG}
                    key={image.src}
                    initial={{ opacity: 0 }}
                    whileHover={{ opacity: 0.9 }}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box m="0 auto" sx={{ zIndex: -10 }}>
                      <MotionTxt>{project.node.frontmatter.client}</MotionTxt>
                      <MotionSubtxt>
                        {project.node.frontmatter.title}
                      </MotionSubtxt>
                    </Box>
                  </Flex>{' '}
                </MotionLink>
              </MotionBox>
            )
          })}
        </motion.div>
      </AnimatePresence>
    </>
  )
}

export default LinkGallery

const MotionLink = styled(motion.custom(AnimationLink))``

const MotionTxt = styled(motion.h3)`
  color: ${props => props.theme.secondary};
  margin-bottom: 0;
`
const MotionSubtxt = styled(motion.h4)`
  margin-bottom: 0;
`
const MotionBox = styled(motion.custom(Box))`
  display: inline-block;
  overflow: hidden;
`
