import React, { useState } from 'react'
import TransitionContext from '@context/transitioncontext'
import { useAllProjectsQuery } from '@queries/ALL_PROJECTS_QUERY'
import LinkGallery from '@components/drilldown/linkgallery'
import { Flex } from 'rebass'
import { rhythm } from '@utils/typography'
import styled from 'styled-components'
import { AnimateSharedLayout, AnimatePresence, motion } from 'framer-motion'
import { below } from '@utils/utilities'
import SEO from '@components/seo'

const spring = { duration: 0.3, ease: [0.6, 0.01, -0.05, 0.9] }

const Projects = props => {
  const { location } = props
  const data = useAllProjectsQuery()
  const { works, projectsSEO } = data
  const socialImage = projectsSEO.childImageSharp.resize

  const { edges: projects } = works
  const { group: tags } = works

  const [posts, setPosts] = useState(projects)
  const [selected, setSelected] = useState('all')

  const filterPosts = value => {
    // eslint-disable-next-line no-shadow
    let posts = projects
    if (value !== 'all') {
      posts = projects.filter(project =>
        project.node.frontmatter.tags.find(tag => tag === value)
      )
    }
    setSelected(value)
    setPosts(posts)
  }

  const FilterTags = (
    <Flex justifyContent={['space-around', 'start', 'start', 'start']}>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence>
          <FilterTag secondary>Filter:</FilterTag>
        </AnimatePresence>
        <FilterTag
          isSelected={selected === 'all'}
          onClick={() => filterPosts('all') && setSelected('all')}
          value="all"
          role="button"
        >
          All
        </FilterTag>
        {tags.map(tagName => (
          <FilterTag
            isSelected={selected === tagName.tag}
            onClick={() => filterPosts(tagName.tag) && setSelected(tagName.tag)}
            key={tagName.tag}
            value={tagName.tag}
            role="button"
          >
            {tagName.tag}
          </FilterTag>
        ))}
      </AnimateSharedLayout>
    </Flex>
  )

  return (
    <TransitionContext location={location}>
      <SEO title="Projects" pathname={location.pathname} image={socialImage} />
      <Flex
        width="100vw"
        height="100vh"
        flexDirection="column"
        px={[`${rhythm(1)}`, `${rhythm(2)}`, `${rhythm(3)}`, `${rhythm(3)}`]}
        py={[`${rhythm(3)}`, `${rhythm(3)}`, `${rhythm(3)}`, `${rhythm(3)}`]}
      >
        {FilterTags}
        <LinkGallery
          itemsPerRow={[2, 2, 2, 3]} // This will be changed to `[2, 3]` later
          projects={posts}
        />
      </Flex>
    </TransitionContext>
  )
}

export default Projects
const FilterTag = ({ value, isSelected, secondary, onClick, children }) => {
  return (
    <TagText onClick={onClick} value={value} secondary={secondary}>
      <motion.h6 initial={false} animate={{ opacity: isSelected ? 1 : 0.5 }}>
        {children}
      </motion.h6>
      {isSelected && (
        <motion.div
          layoutId="underline"
          className="underline"
          initial={false}
          animate={{ opacity: 1 }}
          transition={spring}
        />
      )}
    </TagText>
  )
}
const TagText = styled(motion.div)`
  position: relative;
  margin-right: ${rhythm(1)};
  margin-bottom: ${rhythm(1)};
  ${below.xs`
  margin-right: ${rhythm(1 / 6)};
    font-size:${rhythm(0.5)};
  `}
  .underline {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: white;
  }
  h6 {
    color: ${props => (props.secondary ? props.theme.secondary : 'white')};
    opacity: ${props => props.secondary && `${1}!important`};
    margin-bottom: ${rhythm(1 / 2)};
  }
  ${props =>
    props.secondary &&
    below.xs`
      display:none;
    `}
`
